import styles from "./StoreForm.module.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { useSelector } from "react-redux";
import useStoreForm from "./useStoreForm";
import Validation from "./Validation";
import { CautionIcon } from "../../../assets/svgIcons";
import globalStyles from "./../../../assets/styles/base/globalPage.module.scss";
import { useNavigate } from "react-router-dom";
import EditIconV2 from "../../../assets/svgIcons/EditIconV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationModal from "../LocationModal";

const StoreForm = ({
  isLoadingStates,
  states,
  countries,
  setSelectedCountry,
  storeInfo,
  isEditMode = false,
  handleFetchStores = () => {},
  closeWidget = () => {},
}) => {
  const navigate = useNavigate();
  const {
    isAddingStore,
    isEditingStore,
    storeDetails,
    category_list,
    currencyList,
    isLoadingTimezones,
    timezones,
    bankAccount,
    isLocation,
    setIsLocation,
    setStoreDetails,
    submitStoreInfo,
    selectedHours,
  } = useStoreForm({
    storeInfo,
    isEditMode,
    states,
    countries,
    handleFetchStores,
    closeWidget,
  });

  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ storeDetails });
  const { currency_code, allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <>
      <div className={styles.storeForm}>
        <div className="d-flex align-items-center" style={{ gap: "10px" }}>
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>

          <h4 className="font-weight-semibold mb-0">
            {isEditMode
              ? `Update store (${storeInfo?.name})`
              : "Create a new store"}
          </h4>
        </div>

        {/* form section */}
        <form
          onSubmit={handleSubmit(submitStoreInfo)}
          className={styles.formSection}
        >
          <div className={styles.cardSession}>
            <h5 className="font-weight-semibold">Details</h5>
            <div className={styles.inputSection}>
              <primaryComponents.InputFieldV2
                control={control}
                classNames="white"
                name="storeName"
                label="Store Name"
                placeholder="Enter your store name"
                isRequired={true}
                value={storeDetails.name}
                onChange={({ target: { value } }) => {
                  setStoreDetails((prev) => ({
                    ...prev,
                    name: value,
                  }));
                }}
                errorMessage={errors.storeName}
                onKeyUp={() =>
                  errors.storeName !== undefined && trigger("storeName")
                }
                register={register}
              />
              <div className="w-100 mb-2">
                <primaryComponents.SelectFieldV2
                  control={control}
                  name="storeCategory"
                  label="Business Category"
                  isRequired
                  value={
                    storeDetails?.store_category_label ||
                    "Select business category"
                  }
                  onChange={(value) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      store_category: value,
                    }));
                  }}
                  options={category_list}
                  nameKey="name"
                  idKey="id"
                  errorMessage={errors.storeCategory}
                />
              </div>
            </div>

            <div className={styles.inputSection}>
              <div className="w-100">
                <primaryComponents.PhoneNumberInputFieldV2
                  control={control}
                  classNames="white"
                  name="storePhone"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  //defaultValue={storeDetails?.phone_number}
                  value={storeDetails?.phone_number}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      phone_number: value,
                    }));
                  }}
                  errorMessage={errors?.storePhone}
                  onKeyUp={() =>
                    errors.storePhone !== undefined && trigger("storePhone")
                  }
                  register={register}
                  phoneCodeEditable={true}
                  onChangePhoneCode={(val) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      phone_code: val,
                    }));
                  }}
                  countryCode={storeDetails?.phone_code}
                />
              </div>
              <div className="w-100 mt-2">
                <primaryComponents.InputFieldV2
                  control={control}
                  classNames="white mr-1"
                  name="storeEmail"
                  label="Support Email Address"
                  placeholder="Enter business email address"
                  value={storeDetails?.support_email || ""}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      support_email: value,
                    }));
                  }}
                  errorMessage={errors.storeEmail}
                  register={register}
                />
              </div>
            </div>
          </div>

          <div className={styles.cardSession}>
            <h5 className="font-weight-bold">Currency & VAT</h5>
            <div className={styles.inputSection}>
              <div className="w-100">
                <primaryComponents.SelectFieldV2
                  control={control}
                  name="storeCurrency"
                  label="Store currency"
                  isRequired={isEditMode ? false : true}
                  isDisabled={storeInfo?.has_sales_transaction}
                  value={
                    currencyList?.find(
                      (item) => item.currency === storeDetails?.currency_code,
                    )?.currency || "Select currency"
                  }
                  onChange={(value) => {
                    const symbol = currencyList?.find(
                      (item) => item?.currency === value,
                    )?.currency_symbol;

                    setStoreDetails((prev) => ({
                      ...prev,
                      currency_code: value,
                      currency_symbol: symbol,
                    }));
                  }}
                  options={currencyList}
                  nameKey="currency"
                  idKey="currency"
                />
              </div>
              <div className="w-100 mt-2">
                <primaryComponents.InputFieldV2
                  control={control}
                  classNames="white"
                  name="vat"
                  label="VAT Rate(%)"
                  placeholder="Enter vat rate"
                  value={storeDetails?.vat}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      vat: value,
                    }));
                  }}
                  type="number"
                  min="0"
                  max="100"
                  step="0.01"
                  errorMessage={errors.vat}
                  onKeyUp={() => errors.vat !== undefined && trigger("vat")}
                  register={register}
                />
              </div>
            </div>
          </div>

          <div className={styles.cardSession}>
            <h5 className="font-weight-bold">Location & Time Zone</h5>
            <div className={styles.inputSection}>
              <div className="w-100">
                <primaryComponents.InputFieldV2
                  isRequired
                  control={control}
                  name="storeAddress"
                  label="Store Address"
                  placeholder="Enter your store address"
                  value={storeDetails?.address || ""}
                  onChange={({ target: { value } }) => {
                    setStoreDetails((prev) => ({
                      ...prev,
                      address: value,
                    }));
                  }}
                  className="white"
                  onKeyUp={() =>
                    errors.storeAddress !== undefined && trigger("storeAddress")
                  }
                  errorMessage={errors.storeAddress}
                />
              </div>
              <div className="w-100 mb-2">
                {!isLoadingTimezones ? (
                  <primaryComponents.SelectFieldV2
                    control={control}
                    classNames="white"
                    name="timeZone"
                    label="Time Zone"
                    options={timezones}
                    placeholder="Select your time zone for this store"
                    value={
                      timezones?.find(
                        (timezone) => timezone?.id === storeDetails?.timezone,
                      )?.name || "Select timezone"
                    }
                    onChange={(value) => {
                      setStoreDetails((prev) => ({
                        ...prev,
                        timezone: value,
                      }));
                    }}
                    register={register}
                    nameKey="name"
                    idKey="id"
                  />
                ) : null}
              </div>
            </div>

            <div className={`${globalStyles.cardWithBorder} mb-2`}>
              <div className={styles.storefront}>
                <CautionIcon />
                <p className="small-text-size">
                  The regions you add here will be available for your online
                  customers to choose from when you set up your{" "}
                  <span className="purple--text">
                    {" "}
                    storefront (Lumi Buiness E-commerce).
                  </span>
                </p>
              </div>
              <div
                className={`${styles.cardWithBorder} d-flex flex-wrap align-items-center`}
              >
                <div className={styles.locationCard}>
                  {storeDetails?.country_ids ? (
                    <>
                      <div className={styles.selectedLocation}>
                        <p>
                          <span
                            className={"success--text"}
                            style={{
                              backgroundColor: "#F2EDFE",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "2px",
                            }}
                          >
                            {countries?.find(
                              (country) =>
                                `${country?.id}` ===
                                `${storeDetails?.country_ids?.[0]}`,
                            )?.name || "Select country"}
                          </span>{" "}
                        </p>
                      </div>
                      <div className={styles.selectedLocation}>
                        <p>
                          <span
                            className={"success--text"}
                            style={{
                              backgroundColor: "#F2EDFE",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "2px",
                            }}
                          >
                            {storeDetails?.state_ids?.length || 0}
                          </span>{" "}
                          states selected
                        </p>
                      </div>
                      <div className={styles.selectedLocation}>
                        <p>
                          <span
                            className={"success--text"}
                            style={{
                              backgroundColor: "#F2EDFE",
                              borderRadius: "8px",
                              padding: "5px",
                              height: "2px",
                            }}
                          >
                            {
                              Object.values({
                                ...storeDetails?.city_ids,
                              }).flat()?.length
                            }
                          </span>{" "}
                          cities selected
                        </p>
                      </div>
                    </>
                  ) : (
                    <p className="small-text-size">
                      Click{" "}
                      <span
                        style={{ fontStyle: "italic" }}
                        className="font-weight-bold"
                      >
                        “Add Region”{" "}
                      </span>
                      <span>to select country, states & cities</span>
                    </p>
                  )}
                </div>
                <div className={styles.addRegion}>
                  <primaryComponents.Button
                    onClick={() => setIsLocation(true)}
                    type="button"
                    classNames={`${styles.locationBtn} btn btn--outline`}
                  >
                    <EditIconV2 />
                    <span className="p-1"> Add region </span>
                  </primaryComponents.Button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cardSession}>
            <div className="d-flex flex-wrap" style={{ gap: "30px" }}>
              <div className="mt-3">
                <h5 className="font-weight-semibold">Warehousing</h5>
                <div className="d-flex" style={{ gap: "5px" }}>
                  <p
                    className="gray--text smallest-text-size"
                    style={{ maxWidth: "800px" }}
                  >
                    Do you want this store to serve as a warehouse?
                  </p>
                  <div className="w-50" style={{ width: "200px" }}>
                    <primaryComponents.SelectFieldV2
                      control={control}
                      name="isWarehouse"
                      nameKey="label"
                      idKey="value"
                      isRequired={true}
                      value={storeDetails?.is_warehouse ? "Yes" : "No"}
                      options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ]}
                      onChange={(value) => {
                        setStoreDetails((prev) => ({
                          ...prev,
                          is_warehouse: value,
                        }));
                      }}
                      errorMessage={errors?.isWarehouse}
                    />
                  </div>
                </div>
              </div>
              {allow_ledger_account ? (
                <div style={{ width: "250px" }} className="mt-3">
                  <h5
                    className="font-weight-semibold d-flex align-items-center"
                    style={{ gap: "5px" }}
                  >
                    Accounting
                    <secondaryComponents.ToolTipV2
                      tipText={
                        "<span>Accounting <br><br> This account will be applied on this store.</span>"
                      }
                      className="ml-2"
                    >
                      <CautionIcon />
                    </secondaryComponents.ToolTipV2>
                  </h5>
                  <div className="w-100">
                    <primaryComponents.SelectFieldV2
                      control={control}
                      name="account"
                      nameKey="name"
                      idKey="id"
                      value={
                        storeInfo?.default_bank_account
                          ? storeInfo?.default_bank_account?.account_name
                          : "Assign default account"
                      }
                      options={bankAccount?.data?.map((bank) => ({
                        id: bank?.id,
                        name: bank?.account_name,
                      }))}
                      onChange={(value) => {
                        setStoreDetails((prev) => ({
                          ...prev,
                          default_bank_account: value,
                        }));
                      }}
                    />
                  </div>
                </div>
              ) : null}

              <div className="mt-3" style={{ width: "350px" }}>
                <h5
                  className="font-weight-semibold d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  Credit Limit
                  <secondaryComponents.ToolTipV2
                    tipText={
                      "<span>Credit Limit <br><br> This credit limit will be applied on all customers that purchase from this store.</span>"
                    }
                    className="ml-2"
                  >
                    <CautionIcon />
                  </secondaryComponents.ToolTipV2>
                </h5>
                <div className="w-100">
                  <primaryComponents.InputFieldV2
                    control={control}
                    classNames="white"
                    name="credit_limit"
                    placeholder={
                      storeDetails?.currency_code
                        ? storeDetails?.currency_code
                        : currency_code + " 0"
                    }
                    value={storeDetails?.credit_limit}
                    onChange={({ target: { value } }) => {
                      setStoreDetails((prev) => ({
                        ...prev,
                        credit_limit: value,
                      }));
                    }}
                    type="number"
                    errorMessage={errors.credit_limit}
                    onKeyUp={() =>
                      errors.credit_limit !== undefined &&
                      trigger("credit_limit")
                    }
                    register={register}
                  />
                </div>
                <p className="gray--text" style={{ fontSize: "10px" }}>
                  <span className="error--text">Please Note: </span>This credit
                  limit will be applied on all customers that purchase from this
                  store
                </p>
              </div>
            </div>
            <div className="w-100">
              <h5 className="font-weight-semibold mt-2">
                Auto-Reverse Pending Bills
              </h5>
              <div className="w-5" style={{ width: "300px" }}>
                <primaryComponents.EstimatedInput
                  name="orderReversalWindow"
                  control={control}
                  placeholder="Enter hours e.g., 2"
                  value={storeDetails?.order_reversal_window}
                  onChange={(e) => {
                    let inputValue = parseFloat(e.target.value);
                    if (isNaN(inputValue)) {
                      inputValue = 24;
                    }
                    const integerPart = Math.floor(inputValue);
                    let decimalPart = (inputValue - integerPart).toFixed(2);

                    if (decimalPart > 0.59) {
                      decimalPart = 0.59;
                    }

                    const finalValue = integerPart + parseFloat(decimalPart);

                    setStoreDetails((prev) => ({
                      ...prev,
                      order_reversal_window: finalValue,
                    }));
                  }}
                  isRequired={false}
                  domainOptions={[{ label: "Hours", value: "Hours" }]}
                  selectedDomain={selectedHours}
                  errorMessage={errors?.orderReversalWindow}
                  onKeyUp={() =>
                    errors?.orderReversalWindow !== undefined &&
                    trigger("orderReversalWindow")
                  }
                  register={register}
                  min={0.01}
                  step="0.01"
                />
                <p className="gray--text mt-2" style={{ fontSize: "10px" }}>
                  <span className="error--text">Please Note: </span>
                  Auto-reversals will be defaulted to 24-hours if no value is
                  applied.
                </p>
                <div
                  className="d-flex align-items-center text-center mt-2"
                  style={{ gap: "5px" }}
                >
                  <secondaryComponents.ToolTipV2
                    tipText={
                      "<span> Enables automatic reversal of pending or placed carts after a defined time, releasing items back to inventory and ensuring accurate stock levels.</span>"
                    }
                  >
                    <p>
                      <CautionIcon height="12" width="13" />{" "}
                      <span
                        className="small-text-size purple--text"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        What are pending checkouts?
                      </span>
                    </p>
                  </secondaryComponents.ToolTipV2>
                </div>
              </div>
            </div>
          </div>

          {/* bills  */}
          <div className={styles.cardSession}>
            <div>
              <h5>Features</h5>
              <p>Activate features that are useful for this store.</p>
            </div>

            <div className="d-flex justify-content-between flex-wrap m-3">
              <div>
                <h6>Bills</h6>
                <span className="small-text-size">
                  Generate bills for your customer’s order before payment.
                </span>{" "}
                <br />
                <span
                  className="smallest-text-size"
                  style={{ fontStyle: "italic" }}
                >
                  (Recommended for businesses in the hospitality industry e.g
                  Restaurants, lounges, etc.)
                </span>
              </div>
              <primaryComponents.Button
                type="button"
                classNames={`btn ${
                  !storeDetails?.allow_generate_bill
                    ? "btn--success"
                    : "btn--outline-red"
                }`}
                onClick={() =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_generate_bill: !prev.allow_generate_bill,
                  }))
                }
              >
                <span>
                  {!storeDetails?.allow_generate_bill
                    ? "Activate"
                    : "De-activate"}
                </span>
              </primaryComponents.Button>
            </div>
            <hr />

            <div className="d-flex justify-content-between flex-wrap m-3">
              <div>
                <h6>Tables</h6>
                <span className="small-text-size">
                  Create and assign tables on checkout for orders.
                </span>{" "}
                <br />
                <span
                  className="smallest-text-size"
                  style={{ fontStyle: "italic" }}
                >
                  (Recommended for businesses in the hospitality industry e.g
                  Restaurants, lounges, etc.)
                </span>
              </div>
              <primaryComponents.Button
                type="button"
                classNames={`btn ${
                  !storeDetails?.allow_table
                    ? "btn--success"
                    : "btn--outline-red"
                }`}
                onClick={() =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_table: !prev.allow_table,
                  }))
                }
              >
                <span>
                  {!storeDetails?.allow_table ? "Activate" : "De-activate"}
                </span>
              </primaryComponents.Button>
            </div>
            <hr />

            <div className="d-flex justify-content-between flex-wrap m-3">
              <div>
                <h6>Section Display</h6>
                <span className="small-text-size">
                  Assign orders to the right areas in the section department.
                </span>{" "}
                <br />
                <span
                  className="smallest-text-size"
                  style={{ fontStyle: "italic" }}
                >
                  (Recommended for businesses in the hospitality industry e.g
                  Restaurants, lounges, etc.)
                </span>
              </div>
              <primaryComponents.Button
                type="button"
                classNames={`btn ${
                  !storeDetails?.allow_section
                    ? "btn--success"
                    : "btn--outline-red"
                }`}
                onClick={() =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_section: !prev.allow_section,
                  }))
                }
              >
                <span>
                  {!storeDetails?.allow_section ? "Activate" : "De-activate"}
                </span>
              </primaryComponents.Button>
            </div>
            <hr />

            <div className="d-flex justify-content-between flex-wrap m-3">
              <div>
                <h6>Waybill</h6>
                <span className="small-text-size">
                  Generate and view waybills for orders on checkout and later.
                </span>{" "}
                <br />
                <span
                  className="smallest-text-size"
                  style={{ fontStyle: "italic" }}
                >
                  (Recommended for businesses with a warehouse)
                </span>
              </div>
              <primaryComponents.Button
                type="button"
                classNames={`btn ${
                  !storeDetails?.allow_waybill
                    ? "btn--success"
                    : "btn--outline-red"
                }`}
                onClick={() =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_waybill: !prev.allow_waybill,
                  }))
                }
              >
                <span>
                  {!storeDetails?.allow_waybill ? "Activate" : "De-activate"}
                </span>
              </primaryComponents.Button>
            </div>
            <hr />

            <div className="d-flex justify-content-between flex-wrap m-3">
              <div>
                <h6>Prescription</h6>
                <span className="small-text-size">
                  Generate and view prescriptions for orders on checkout.
                </span>{" "}
                <br />
                <span
                  className="smallest-text-size"
                  style={{ fontStyle: "italic" }}
                >
                  (Recommended for Pharmacies)
                </span>
              </div>
              <primaryComponents.Button
                type="button"
                classNames={`btn ${
                  !storeDetails?.allow_prescription
                    ? "btn--success"
                    : "btn--outline-red"
                }`}
                onClick={() =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_prescription: !prev.allow_prescription,
                  }))
                }
              >
                <span>
                  {!storeDetails?.allow_prescription
                    ? "Activate"
                    : "De-activate"}
                </span>
              </primaryComponents.Button>
            </div>
            <hr />

            <div className="d-flex justify-content-between flex-wrap m-3">
              <div>
                <h6>Custom Pricing</h6>
                <span className="small-text-size">
                  Allow your cashiers effortlessly update product prices on the
                  spot while selling
                </span>{" "}
                <br />
                <span
                  className="smallest-text-size"
                  style={{ fontStyle: "italic" }}
                >
                  (Recommended for businesses with a warehouse)
                </span>
              </div>
              <primaryComponents.Button
                type="button"
                classNames={`btn ${
                  !storeDetails?.allow_custom_price
                    ? "btn--success"
                    : "btn--outline-red"
                }`}
                onClick={() =>
                  setStoreDetails((prev) => ({
                    ...prev,
                    allow_custom_price: !prev.allow_custom_price,
                  }))
                }
              >
                <span>
                  {!storeDetails?.allow_custom_price
                    ? "Activate"
                    : "De-activate"}
                </span>
              </primaryComponents.Button>
            </div>
            <hr />

            <div className={`${styles.fixedBtn}`}>
              <primaryComponents.Button
                classNames={"btn btn--primary ml-auto"}
                isLoading={isEditMode ? isEditingStore : isAddingStore}
                isDisabled={isEditMode ? isEditingStore : isAddingStore}
                loadingText={isEditMode ? "Updating..." : "Creating..."}
              >
                {isEditMode ? "Update Store" : "Create Store"}
              </primaryComponents.Button>
            </div>
          </div>
        </form>
      </div>

      <secondaryComponents.Modal
        isActive={isLocation}
        closeModal={() => setIsLocation(false)}
        width={650}
      >
        <LocationModal
          closeModal={() => setIsLocation(false)}
          isLoadingStates={isLoadingStates}
          states={states}
          countries={countries}
          setSelectedCountry={setSelectedCountry}
          storeDetails={storeDetails}
          setStoreDetails={setStoreDetails}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default StoreForm;
