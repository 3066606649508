import httpClient from "../axiosBase/axiosHandler";
import { cleanObject } from "../helpers";
// import publicHttpClient from "../axiosBase/axiosPublic";

export const createStore = (payload) => {
  return httpClient.post("/store/", payload);
};

export const getStoreDetails = (storeId) => {
  return httpClient.get(`/store/${storeId}/`);
};

export const getStoreList = () => {
  return httpClient.get("/store/");
};

export const updateStoreDetails = ({ data, id }) => {
  return httpClient.patch(`/store/${id}/`, data);
};

export const createCategory = (payload) => {
  return httpClient.post("/product-category/", payload);
};

export const getCategoryList = () => {
  return httpClient.get("/product-category/");
};

export const getCategoriesLinkedToProduct = (queries) => {
  return httpClient.get(`/product-category/linked-to-products/${queries}`);
};

export const getCategoryDetails = (categoryId) => {
  return httpClient.get(`/product-category/${categoryId}`);
};

export const updateCategoryDetails = (categoryId, update) => {
  return httpClient.patch(`/product-category/${categoryId}/`, update);
};

export const deleteCategory = (categoryId) => {
  return httpClient.delete(`/product-category/${categoryId}/`);
};

export const getProductList = (queries) => {
  return httpClient.get(`/product/${queries}`);
};

export const getProduct = (productId) => {
  return httpClient.get(`/product/${productId}/`);
};

export const addSingleProduct = (productDetails) => {
  return httpClient.post("/product/", productDetails);
};

export const getProductUnits = () => {
  return httpClient.get("/product/units/");
};

export const getProductTypes = () => {
  return httpClient.get("/product/stock-type-choices/");
};

export const addMultipleProducts = (file) => {
  return httpClient.post("/product/bulk-create/", file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateProductDetails = ({ id, submissionData }) => {
  return httpClient.patch(`/product/${id}/`, submissionData);
};

export const uploadProductImage = (image) => {
  return httpClient.post("/media/", image, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const deleteProductImage = (mediaId) => {
  return httpClient.delete(`/media/${mediaId}/`);
};

export const deleteProduct = ({ id, isService }) => {
  const url = isService ? `/service/${id}/` : `/product/${id}/`;
  return httpClient.delete(url);
};

export const getOrderList = (queries = "", queryParams = {}) => {
  return httpClient.get(`/order/${queries}`, { params: queryParams });
};

export const getOrders = (queryParams) => {
  return httpClient.get("/order/", { params: { ...queryParams } });
};

export const getOrderDetails = (orderId) => {
  return httpClient.get(`/order/${orderId}/`);
};

export const acceptOrRejectStorefrontOrder = ({
  id,
  rejection_reason,
  isAccept,
}) => {
  const url = isAccept
    ? `/order/${id}/accept-in-store-order/`
    : `/order/${id}/reject-in-store-order/`;
  return httpClient.patch(url, cleanObject({ rejection_reason }));
};

export const generateOrderReceipt = (orderId, isUpdatedBill = false) => {
  const urlPath = isUpdatedBill
    ? `/order/${orderId}/generate-bill-receipt/`
    : `/order/${orderId}/generate-receipt/`;
  return httpClient.post(urlPath);
};

export const reverseOrder = ({ orderId }) => {
  return httpClient.post(`/order/${orderId}/cancel_order/`);
};

export const getStockHistory = (queries) => {
  return httpClient.get(`/product/stock-history/${queries}`);
};

export const getProductStockHistory = (product_id) => {
  return httpClient.get(`/product/stock-history/?product_id=${product_id}`);
};

export const restockProduct = ({ id, payload }) => {
  return httpClient.patch(`/product/${id}/restock-product/`, payload);
};

export const getProfitTrend = (query) => {
  return httpClient.get(`/inventory-analytics/profit-trend/${query}`);
};

export const getRevenueTrend = (query) => {
  return httpClient.get(`/inventory-analytics/revenue-trend/${query}`);
};

export const getTopProductsList = (query) => {
  return httpClient.get(`/inventory-analytics/top-products/${query}`);
};

export const getAnalyticsData = (query) => {
  return httpClient.get(`/inventory-analytics/analytics-data/${query}`);
};

export const updateProductTag = (data) => {
  const orderId = data.order_id;
  delete data.order_id;
  return httpClient.patch(`/order/${orderId}/`, data);
};

export const getServiceList = (queries) => {
  return httpClient.get(`/service/${queries}`);
};

export const createService = (payload) => {
  return httpClient.post("/service/", { ...payload });
};

export const getServiceDetails = (serviceId) => {
  return httpClient.get(`/service/${serviceId}/`);
};

export const updateServiceDetails = ({ id, submissionData }) => {
  return httpClient.patch(`/service/${id}/`, submissionData);
};

export const deleteService = (serviceId) => {
  return httpClient.delete(`/service/${serviceId}/`);
};

export const downloadStockHistory = (data) => {
  return httpClient.post("/product/export-stock-history/", data);
};

export const exportProducts = (data) => {
  return httpClient.post("/export-products/export/", data);
};

export const getCreditLog = (queries) => {
  return httpClient.get(`/customers/${queries}`);
};

export const transferStock = (data) => {
  return httpClient.post("/product/transfer-stock/", data);
};

export const removeStock = (data) => {
  return httpClient.patch("/product/remove-stock/", data);
};

export const returnStock = (data) => {
  return httpClient.post("/product/return-products/", data);
};

export const getOrderTables = (queries) => {
  return httpClient.get(`/order-tables/${queries}`);
};

export const createOrderTable = (data) => {
  return httpClient.post("/order-tables/", data);
};

export const getOrderTableDetails = (table_id) => {
  return httpClient.get(`/order-tables/${table_id}`);
};

export const updateOrderTable = (data) => {
  const { table_id } = data;
  delete data.table_id;
  return httpClient.patch(`/order-tables/${table_id}/`, data);
};

export const clearOrderTable = (table_id) => {
  return httpClient.patch(`/order-tables/${table_id}/clear-table/`, {
    table_id,
  });
};

export const deleteOrderTable = (table_id) => {
  return httpClient.delete(`/order-tables/${table_id}`);
};

export const getStoreCategoryList = () => {
  return httpClient.get("/store/store-categories/");
};

export const getReconciliations = (queries) => {
  return httpClient.get(`/reconciliations/${queries}`);
};

export const startReconciliations = (data) => {
  return httpClient.post("/reconciliations/", data);
};

export const getReconciliationsProducts = ({ id, ...params }) => {
  return httpClient.get(`/reconciliations/${id}/products-list/`, {
    params: { ...params, per_page: 50 },
  });
};

export const getReconciliationDiscrepancyReasons = () => {
  return httpClient.get("/discrepancy-reasons/");
};

export const submitReconciledData = ({ id, data }) => {
  return httpClient.patch(`/reconciliations/${id}/submit/`, data);
};

export const saveReconciledDraft = ({ id, data }) => {
  return httpClient.patch(`/reconciliations/${id}/save-draft/`, data);
};

export const approveReconciliation = ({ id }) => {
  return httpClient.patch(`/reconciliations/${id}/approve/`);
};
export const rejectReconciliation = ({ id }) => {
  return httpClient.patch(`/reconciliations/${id}/reject/`);
};

export const getReconciliationDetails = (id) => {
  return httpClient.get(`/reconciliations/${id}/`);
};

export const createProductBarcode = ({ id }) => {
  return httpClient.post(`product/${id}/barcode/`, {});
};

export const getProductBarcode = (id) => {
  return httpClient.get(`product/${id}/barcode/`);
};

export const bulkRestock = (data) => {
  return httpClient.patch("product/restock-bulk-products/", data);
};

export const getInvoiceDetails = (id) => {
  return httpClient.get(`/invoices/${id}/`);
};

export const generatePrescription = (order_id, data) => {
  return httpClient.post(
    `/prescriptions/${order_id}/generate-prescription/`,
    data,
  );
};

export const getGeneratedPrescriptions = (order_id) => {
  return httpClient.get(`/prescriptions/${order_id}/view-prescriptions/`);
};

export const getPrescriptionUnits = () => {
  return httpClient.get("/prescriptions/units/");
};

export const getMenus = (queries) => {
  return httpClient.get(`/storefronts/${queries}`);
};

export const getMenuDetails = (menu_id) => {
  return httpClient.get(`/storefronts/${menu_id}/`);
};

export const getMenuProducts = ({ store_id, page, query }) => {
  return httpClient.get("/product/", {
    params: {
      store_id,
      page: page ? page : undefined,
      per_page: 50,
      query: query ? query : undefined,
    },
  });
};

export const deleteMenu = (menu_id) => {
  return httpClient.delete(`/storefronts/${menu_id}/`);
};

export const addMenu = (data) => {
  return httpClient.post("/storefronts/", data);
};

export const editMenu = ({ data, menuId }) => {
  return httpClient.patch(`/storefronts/${menuId}/`, data);
};

export const updateStorefrontColor = (data) => {
  return httpClient.post("/storefronts/update_storefront_colour/", data);
};

export const uploadLogo = (data) => {
  return httpClient.post("/media/", data);
};

export const getViewMenuList = ({ id }) => {
  return httpClient.get(`/storefronts/${id}/view-storefront-item/`);
};

export const getViewMenuDetail = ({ id }) => {
  return httpClient.get(`/storefronts/${id}/view-storefront-detail/`);
};

export const getPaymentGateways = () => {
  return httpClient.get("/payment-gateways/");
};

export const getPaymentGatewayDetail = (id) => {
  return httpClient.get(`/payment-gateways/${id}/`);
};

export const getMerchantWebhook = (merchant_token) => {
  return httpClient.post(`/gateway-webhook/merchant/${merchant_token}/`, {});
};

export const createPaymentGateway = (data) => {
  return httpClient.post("/payment-gateways/", data);
};

export const editPaymentGateway = ({ paymentId, data }) => {
  return httpClient.patch(`/payment-gateways/${paymentId}/`, data);
};

export const deletePaymentGateway = (id) => {
  return httpClient.delete(`/payment-gateways/${id}/`);
};

export const activatePaymentGateway = (id) => {
  return httpClient.post(
    `/payment-gateways/${id}/activate-payment-gateway/`,
    {},
  );
};

export const generateGuestUserToken = () => {
  return httpClient.post("/register/guest-user-token/");
};

export const menuAddBulkToCart = ({ data }) => {
  return httpClient.post("/public/orders/add-bulk-items-to-cart/", data);
};

export const menuOrder = ({ data }) => {
  return httpClient.post("/public/orders/", data);
};

export const menuConfirmCustomer = ({ data }) => {
  return httpClient.post("/public/orders/confirm-customer/", data);
};

export const ordersTables = (id) => {
  return httpClient.get(`/public/orders/${id}/tables/`);
};

export const fetchPaymentMethods = (data) => {
  return httpClient.post("/public/orders/fetch-payment-methods/", data);
};

export const menuProcessPayment = (data) => {
  return httpClient.post("/public/orders/process-payment/", data);
};

export const menuPayInstoreDelivery = (data) => {
  return httpClient.post("/public/orders/pay-in-store-or-on-delivery/", data);
};

export const createCompositeProduct = (compositeDetails) => {
  return httpClient.post("/composite-product/", compositeDetails);
};

export const editCompositeProduct = ({ compositeDetails, id }) => {
  return httpClient.patch(`/composite-product/${id}/`, compositeDetails);
};

export const getCompositeProduct = (id) => {
  return httpClient.get(`/composite-product/${id}/`);
};

export const getMenuCategoriesList = ({ id }) => {
  return httpClient.get(`/storefronts/${id}/retrieve-storefront-categories/`);
};

export const orderMenuCategoryList = ({ id, data }) => {
  return httpClient.post(`/storefronts/${id}/order-storefront-category/`, data);
};

export const activateStorefrontPayment = (id) => {
  return httpClient.post(`/storefronts/${id}/activate-storefront-payment/`);
};

export const activateInstoreCheckout = (id) => {
  return httpClient.post(
    `/storefronts/${id}/activate-storefront-instore-checkout/`,
  );
};

export const activateDeliveryCheckout = (id) => {
  return httpClient.post(`/storefronts/${id}/activate-storefront-delivery/`);
};

export const activatePayInstore = (id) => {
  return httpClient.post(`/storefronts/${id}/activate-pay-instore/`);
};

export const activatePayOnDelivery = (id) => {
  return httpClient.post(`/storefronts/${id}/activate-pay-on-delivery/`);
};

export const activatePayWithPaystack = (id) => {
  return httpClient.post(`/storefronts/${id}/activate-pay-with-paystack/`);
};

export const activatePayWithStripe = (id) => {
  return httpClient.post(`/storefronts/${id}/activate-pay-with-stripe/`);
};

export const getPricingTypes = () => {
  return httpClient.get("/product/pricing-types/");
};

export const createPricingTypes = (data) => {
  return httpClient.post("/product/create-pricing-types/", data);
};

export const replicateProduct = (data) => {
  return httpClient.post("/product/replicate/", data);
};

export const deleteVariantFromBackend = ({ id }) => {
  return httpClient.delete(`/product/${id}/delete-variant/`);
};

export const generateOrderInvoice = ({ id }) => {
  return httpClient.post(`/order/${id}/generate-invoice/`, {});
};

export const bulkUpdateProductPrice = (data) => {
  return httpClient.post("/product/bulk-update-product-pricing/", data);
};

export const bulkTransferstock = (data) => {
  return httpClient.post("/stock-transfer/", data);
};

export const getStockTransfersList = (queries) => {
  return httpClient.get(`/stock-transfer/${queries}`);
};

export const getStockTransferItems = ({ id, ...params }) => {
  return httpClient.get(`/stock-transfer/${id}/product-list/`, {
    params: { ...params, per_page: 50 },
  });
};

export const getStockTransferDetails = (id) => {
  return httpClient.get(`/stock-transfer/${id}/`);
};

export const approveStockTransfer = (id) => {
  return httpClient.patch(`/stock-transfer/${id}/approve/`);
};

export const rejectStockTransfer = (id) => {
  return httpClient.patch(`/stock-transfer/${id}/decline/`);
};

export const bulkTransferstockUpdate = ({ data, id }) => {
  return httpClient.patch(`/stock-transfer/${id}/edit-product-list/`, data);
};

export const bulkTransferstockDelete = ({ data, id }) => {
  return httpClient.delete(`/stock-transfer/${id}/delete-product-item/`, {
    data,
  });
};

export const updateCreditLimit = ({ id, credit_limit }) => {
  return httpClient.patch(`/customers/${id}/edit_customer_credit_limit/`, {
    credit_limit: credit_limit,
  });
};

export const sendCreditLimitReminder = ({ id, data }) => {
  return httpClient.post(`/customers/${id}/message_customer/`, data);
};

export const getTrackItems = (queries = "", otherParams = {}) => {
  const url =
    otherParams?.status === "sold"
      ? "/product/product-inventory-sold-items/"
      : "/product/product-inventory-items/";
  return httpClient.get(`${url}${queries}`, {
    params: { ...otherParams },
  });
};

export const updateProductTrackItem = ({ data, id }) => {
  return httpClient.patch(
    `/product/${id}/update-product-inventory-item/`,
    data,
  );
};

export const deleteProductTrackItem = ({ id }) => {
  return httpClient.delete(`/product/${id}/delete-product-inventory-item/`);
};

export const getShippingsList = (queries = "") => {
  return httpClient.get(`/shipping-groups/${queries}`);
};

export const getShipping = (shippingId) => {
  return httpClient.get(`/shipping-groups/${shippingId}/`);
};

export const removeShipping = ({ id }) => {
  return httpClient.delete(`/shipping-groups/${id}`);
};

export const storeFrontShippingGroup = (menuId) => {
  return httpClient.get(`/public/orders/${menuId}/shipping-groups/`);
};

export const getRiderOrders = ({ queries, ...params }) => {
  return httpClient.get(`/public/orders/${queries}`, {
    params: { ...params, per_page: 50 },
  });
};

export const getRiderOrderDetails = (orderId) => {
  return httpClient.get(`/public/orders/${orderId}/`);
};

export const updateOrderStatusConfirmation = ({ orderId, data }) => {
  return httpClient.patch(
    `/public/orders/${orderId}/update-order-status-via-rider/`,
    data,
  );
};

export const getProductsBatch = (queries) => {
  return httpClient.get(`/product-batch/${queries}`);
};

export const getProductBatchDetails = (productId) => {
  return httpClient.get(`/product/product-batch/${productId}`);
};

export const updateProductBatchDetails = ({ data, id }) => {
  return httpClient.patch(`/product/${id}/product-batch/`, data);
};

export const getStoreWallets = (queries) => {
  return httpClient.get(`/store-wallet/${queries}`);
};

export const getStoreWalletDetails = (walletId) => {
  return httpClient.get(`/store-wallet/${walletId}/`);
};

export const fundStoreWallet = (payload) => {
  return httpClient.post("/store-wallet/fund-wallet/", payload);
};

export const debitStoreWallet = (payload) => {
  return httpClient.post("/store-wallet/debit-wallet/", payload);
};

export const downloadStoreWalletStatement = (payload) => {
  return httpClient.post("/store-wallet/statement/", payload);
};

export const completeDebitTnx = (payload) => {
  return httpClient.post("/store-wallet/confirm-wallet-debit/", payload);
};

export const completeDebitTnxViaSellMode = (payload) => {
  return httpClient.post("/order/pay-with-store-wallet-otp/", payload);
};

export const resendDebitTnxOtp = (payload) => {
  return httpClient.post("/store-wallet/resend-debit-otp/", payload);
};

export const toggleStoreWalletStatusMutation = (payload) => {
  return httpClient.patch(
    `/store-wallet/${payload?.id}/toggle-store-wallet/`,
    {},
  );
};

export const toggleStoreWallet2fAuthMutation = (payload) => {
  return httpClient.patch(
    `/store-wallet/${payload?.id}/toggle-2factor-auth/`,
    {},
  );
};

export const getStoreWalletTransaction = (queries, queryParams) => {
  return httpClient.get(`/store-wallet-transactions/${queries}`, {
    params: queryParams,
  });
};

export const getStoreWalletTransactionDetails = (tnxId) => {
  return httpClient.get(`/store-wallet-transactions/${tnxId}/`);
};

export const createStoreWallet = (payload) => {
  return httpClient.post("/store-wallet/create-store-wallet/", payload);
};

export const payViaStoreWalletMutation = (payload) => {
  return httpClient.post("/order/pay-with-store-wallet/", payload);
};

export const createProductUnit = (payload) => {
  return httpClient.post("/product-units/", payload);
};

export const downloadDebtorStatement = (payload) => {
  return httpClient.post("/credits/customer-statement/", payload);
};
